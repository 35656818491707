import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Cash = './assets/Cash.png';
const Credits = './assets/Credits.png';
const OnlineTransfer = './assets/OnlineTransfer.png';

const data = {
  cash: {
    title: 'Cash*',
    description: 'Pay upfront per job when the GoGetter completes your tasks.',
    disclaimer: '*Available for certain job types only',
    image: <StaticImage src={Cash} placeholder="blurred" alt="Cash" />
  },
  gogetPay: [
    {
      id: 1,
      title: 'Credits',
      description:
        'Fully customisable for multiple jobs, cost of goods and delivery fees.',
      image: <StaticImage src={Credits} placeholder="blurred" alt="Credits" />
    },
    {
      id: 2,
      title: 'Online transfer',
      description:
        'Pay per job via multiple banks on the app to ensure hassle-free payments.',
      image: (
        <StaticImage
          src={OnlineTransfer}
          placeholder="blurred"
          alt="OnlineTransfer"
        />
      )
    }
  ]
};

export { data };
