import React from 'react';
import { Home, Favorite } from '@material-ui/icons';
import { StaticImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components/macro';

const girlShopping = './assets/girlShopping.png';

const ShareICONCSS = css`
  font-size: 17px !important;
  color: #fff !important;
`;
const HomeIcon = styled(Home)`
  ${ShareICONCSS};
`;
const FavIcon = styled(Favorite)`
  ${ShareICONCSS};
`;
const data = {
  image: (
    <StaticImage src={girlShopping} placeholder="blurred" alt="Girl shopping" />
  ),
  items: [
    {
      id: 1,
      icon: <HomeIcon />,
      category: 'Home',
      price: 'Min RM9'
    },
    {
      id: 2,
      icon: <FavIcon />,
      category: 'Life',
      price: 'Min RM9 - RM10'
    }
  ]
};

export { data };
